<template>
  <FlexColContent :class="$style.wrap">
    <div :class="$style.searchHeader">
      <div :class="$style.tipText">
        {{ $t('hat.clockRecord.powerDurations.tipsText') }}
      </div>
      <a-range-picker
        style="width: 300px;"
        format="YYYY-MM-DD"
        v-model="dateRange"
        :ranges="presetTime"
        :allowClear="false"
        :placeholder="[
          $t('hat.clockRecord.startDate'),
          $t('hat.clockRecord.endDate'),
        ]"
        :disabled-date="disabledDate"
        @change="handleSearchTime"
        @calendarChange="calendarPriceRangeChange"
      />
      <a-select
        show-search
        v-model="searchParams.projectId"
        :placeholder="$t('hat.managementModal.event.project')"
        style="width: 200px;margin: 0 10px;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleProjectSearch"
        @change="handleProjectChange"
      >
        <!-- @search="handleSearch"
        @change="handleChange" -->
        <a-select-option
          v-for="item in projects"
          :value="item.pkId"
          :key="item.pkId"
          >{{ item.pmName }}</a-select-option
        >
      </a-select>
      <a-select
        mode="multiple"
        show-search
        v-model="searchParams.deviceGroupIds"
        :placeholder="$t('hat.clockRecord.allGroups')"
        style="width: 200px;margin: 0 10px;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleGroupSearch"
        @change="handleGroupChange"
      >
        <a-select-option
          v-for="item in groups"
          :value="item.pkId"
          :key="item.pkId"
          >{{ item.deviceGroupName }}</a-select-option
        >
      </a-select>
      <a-select
        show-search
        v-model="searchParams.userId"
        :placeholder="$t('hat.clockRecord.allMembers')"
        style="width: 200px;margin: 0 10px;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearch"
        @change="handleChange"
      >
        <a-select-opt-group>
          <span slot="label"
            ><a-icon type="user" />{{ $t('hat.clockRecord.members') }}</span
          >
          <a-select-option value="">
            {{ $t('hat.clockRecord.allMembers') }}
          </a-select-option>
          <a-select-option
            v-for="item in data.member"
            :key="item.pkId"
            :value="item.pkId"
          >
            {{ item.memberName }}
          </a-select-option>
        </a-select-opt-group>
        <a-select-opt-group>
          <span slot="label"
            ><a-icon type="user" />{{ $t('hat.clockRecord.external') }}</span
          >
          <!-- antDesign的bug不加这个option会报错 -->
          <a-select-option value="external">
            {{ $t('hat.clockRecord.allMembers') }}
          </a-select-option>
          <a-select-option
            v-for="item in data.external"
            :key="item.pkId"
            :value="item.pkId"
          >
            {{ item.entMemberName }}
          </a-select-option>
        </a-select-opt-group>
      </a-select>
      <async-button
        type="primary"
        size="small"
        style="min-width: 50px; height: 30px;"
        :click="exportExcel.bind(this)"
      >
        {{ $t('common.export') }}
      </async-button>
    </div>
    <template #footer>
      <PaginationTable
        ref="tableRef"
        :showTotalOther="true"
        :columns="columns"
        :api="api"
        :extraKeys="{ showKey: false }"
        row-key="pkId"
        :slotKeys="['action', 'startTime', 'endTime']"
        :titleSlotKeys="[
          'startTimeTitle',
          'endTimeTitle',
          'totalDurationTitle',
          'enterDurationTitle',
          'leaveDurationTitle',
        ]"
        :search-params="searchParams"
      >
        <template slot="action" slot-scope="{ record }">
          <div :class="$style.buttonGroups">
            <span :class="$style.button" @click="handleDetail(record)">{{
              $t('hat.clockRecord.detailed')
            }}</span>
          </div>
        </template>
      </PaginationTable>
    </template>
  </FlexColContent>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import FlexColContent from '@/views/hat/components/flex-col-content';
import PaginationTable from '@/views/hat/components/pagination-table';
import { getList, exportExcel } from '@/services/smart-hat/boottime';
import {
  reqGetStaff,
  reqGetStaffInner,
  reqGetStaffExternal,
} from '@/services/attendance/list';
import { createModal } from '@triascloud/x-components';
import Detail from './components/power-detail.vue';
import AsyncButton from '@/components/async-button';
import { Debounce } from 'lodash-decorators';
import { clipboardWrite } from '@triascloud/utils';
import { getPagePm } from '@/services/smart-hat/pm';
import { getPageByName } from '@/services/smart-hat/group';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    FlexColContent,
    PaginationTable,
    AsyncButton,
  },
})
export default class PowerDuration extends Vue {
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  api = getList;
  searchParams = {
    startTime:
      this.$moment()
        .endOf('day')
        .valueOf() -
      6 * 86400000 -
      DAY +
      1000,
    endTime: this.$moment()
      .endOf('day')
      .valueOf(),
    userId: undefined,
    projectId: undefined,
    deviceGroupIds: [],
  };
  dateRange = [];
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success(this.$t('camera.replicatingSuccess'));
    });
  }

  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('hat.clockRecord.activityRecord.name'),
        width: 100,
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.roleCOList.length > 0 ? (
                    <div>
                      {this.$t('iotScreenManage.role')}：
                      {this.roleNames(record.roleCOList)}
                    </div>
                  ) : null}

                  <div
                    onClick={() => {
                      this.copyPhone(record.phone);
                    }}
                  >
                    {this.$t('enterpriseManagement.camera.tableColumn.phone')}：
                    {record.phone}
                  </div>
                </div>
              </template>
              {record.avatar ? (
                <x-oss-image
                  class={this.$style.avatar}
                  ossPath={record.avatar}
                />
              ) : null}
              <span>{record.userName}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        align: 'left',
        title: this.$t('hat.clockRecord.activityRecord.date'),
        width: 120,
        ellipsis: true,
        customRender: (text, record) =>
          this.$moment(record.date).format('YYYY-MM-DD'),
      },
      {
        align: 'left',
        title: this.$t('hat.clockRecord.activityRecord.group'),
        width: 120,
        ellipsis: true,
        dataIndex: 'groupName',
        // customRender: (text, record) =>
        //   record.groupNameList.length > 0
        //     ? record.groupNameList.join('、')
        //     : '',
      },
      {
        title: this.$t('hat.clockRecord.powerDurations.firstStartupTime'),
        width: 250,
        ellipsis: true,
        dataIndex: 'firstTime',
        customRender: text =>
          text ? this.$moment(text).format('HH:mm:ss') : '-',
      },
      {
        title: this.$t('hat.clockRecord.powerDurations.lastShutdownTime'),
        width: 250,
        ellipsis: true,
        dataIndex: 'lastTime',
        customRender: text =>
          text ? this.$moment(text).format('HH:mm:ss') : '-',
      },
      {
        title: this.$t(
          'hat.clockRecord.powerDurations.accumulatedOnlineDuration',
        ),
        dataIndex: 'totalOnlineTime',
        width: 150,
        ellipsis: true,
        customRender: text =>
          text < 0 ? '-' : text + this.$t('hat.screen.hours'),
      },
      {
        title: this.$t('electricBoxMock.columnsInfo.operation'),
        width: 120,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  data = {
    external: [],
    member: [],
  };
  created() {
    if (this.$route.query.userId) {
      this.searchParams.userId = this.$route.query.userId;
    }
    if (this.$route.query.date && this.$route.query.date === 'THIRTY_DAY') {
      const startDate = new Date().getTime() - 2592000000;
      const endDate = new Date().getTime();
      this.dateRange = [
        // 显示近30天 30 * 86400000
        this.$moment(startDate).format('YYYY-MM-DD'),
        this.$moment(endDate).format('YYYY-MM-DD'),
      ];
      this.searchParams.startTime = this.$moment(startDate).valueOf();
      this.searchParams.endTime = this.$moment(endDate).valueOf() + DAY - 1000;
    } else {
      this.dateRange = [
        //默认显示一周   今天是最后一天
        this.$moment(new Date().getTime() - 518400000).format('YYYY-MM-DD'),
        this.$moment(new Date().getTime()).format('YYYY-MM-DD'),
      ];
    }
  }
  mounted() {
    this.initProject();
    this.staffList();
  }
  projects = [];
  async initProject(search) {
    let params = {};
    if (search) {
      params.input = search;
    } else {
      delete params.input;
    }
    const { records } = await getPagePm(params);
    const arr = [
      {
        pmName: '全部',
        pkId: '',
      },
      {
        pmName: '未加入项目',
        pkId: 'NOT_JOIN',
      },
    ];
    this.projects = arr.concat(records);
  }
  groups = [];
  async handleProjectSearch(search) {
    await this.initProject(search);
  }
  async handleProjectChange(projectId, search) {
    let params = {};
    if (projectId === '') {
      params = {};
    } else {
      params = {
        projectId: projectId,
      };
    }
    if (typeof search === 'string') {
      if (search) {
        params.input = search;
      } else {
        delete params.input;
      }
    }
    const { records } = await getPageByName(params);
    const arr = [
      {
        deviceGroupName: '未加入编组',
        pkId: 'NOT_JOIN',
      },
    ];
    this.groups = arr.concat(records);
    this.searchParams.deviceGroupIds = [];
    this.$refs.tableRef.search();
  }
  async handleGroupSearch(search) {
    await this.handleProjectChange(this.searchParams.projectId, search);
  }
  handleGroupChange() {
    this.$refs.tableRef.search();
  }

  async staffList() {
    const member = await reqGetStaffInner();
    const external = await reqGetStaffExternal();
    this.data.member = member.records;
    this.data.external = external.records;
  }
  async fetch(value) {
    const result = await reqGetStaff(value);
    this.data = result;
  }
  async handleSearchTime(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf() + DAY - 1000;
    this.$refs.tableRef.search();
  }
  handleSearch(value) {
    this.fetch(value);
  }
  async handleChange(v) {
    this.searchParams.userId = v === 'external' ? '' : v;
    this.$refs.tableRef.search();
  }
  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 360 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
  @Debounce(500)
  async exportExcel() {
    try {
      const data = { ...this.searchParams };
      if (!data.userId) {
        data.userId = undefined;
      }
      await exportExcel(data);
      this.$message.success(this.$t('common.exportSuccessful'));
    } catch {
      return false;
    }
  }
  handleDetail(record) {
    createModal(() => <Detail record={record} />, {
      width: 850,
      maskClosable: false,
      title: `${record.userName}${this.$t(
        'hat.clockRecord.powerDuration.modalTitle',
      )}(${this.$moment(record.date).format('YYYY-MM-DD')})`,
    });
  }
}
</script>
<style lang="less" module>
.wrap {
  :global {
    .ant-pagination-total-text {
      float: left;
    }
  }
}
.searchHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  .tipText {
    color: var(--font-info);
    margin-right: auto;
    align-self: flex-end;
    font-size: 14px;
  }
}
.buttonGroups {
  .button {
    cursor: pointer;
    color: var(--primary);
    margin-right: 20px;
  }
  .button:last-of-type {
    margin-right: 0;
  }
}
.avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
  margin-right: 10px;
}
</style>
