<template>
  <FlexColContent>
    <div :class="$style.searchHeader">
      <div :class="$style.tipText" v-html="tipsText"></div>
      <a-tooltip>
        <template #title>{{ $t('hat.clockRecord.tips') }}</template>
        <a-icon @click="go" :class="$style.cursor" type="setting" />
      </a-tooltip>
      <a-range-picker
        v-model="searchForm.timeRange"
        :ranges="presetTime"
        style="width: 320px;text-align: left;"
        :show-time="{ format: 'HH:mm' }"
        format="YYYY-MM-DD HH:mm"
        :placeholder="[
          $t('hat.clockRecord.startDate'),
          $t('hat.clockRecord.endDate'),
        ]"
        @change="handleSearchTime"
        @ok="handleSearchTimeOK"
      />
      <a-select
        show-search
        v-model="value.value"
        :placeholder="$t('hat.clockRecord.allMembers')"
        style="width: 200px;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearch"
        @change="handleChange1"
      >
        <a-select-opt-group>
          <span slot="label"
            ><a-icon type="user" />{{ $t('hat.clockRecord.members') }}</span
          >
          <a-select-option value="u1" v-show="false">
            {{ $t('hat.clockRecord.allMembers') }}
          </a-select-option>
          <a-select-option
            v-for="item in data.member"
            :key="item.pkId"
            :value="item.pkId"
          >
            {{ item.memberName }}
          </a-select-option>
        </a-select-opt-group>
        <a-select-opt-group>
          <span slot="label"
            ><a-icon type="user" />{{ $t('hat.clockRecord.external') }}</span
          >
          <!-- antDesign的bug不加这个空option会报错 -->
          <a-select-option value="u0" v-show="false">
            {{ $t('hat.clockRecord.allMembers') }}
          </a-select-option>
          <a-select-option
            v-for="item in data.external"
            :key="item.pkId"
            :value="item.pkId"
          >
            {{ item.entMemberName }}
          </a-select-option>
        </a-select-opt-group>
      </a-select>
    </div>
    <template #footer>
      <a-table
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        @change="handlePageChange"
        :class="$style.table"
        :scroll="{ x: 1600 }"
        :loading="loading"
      >
        <template slot="times" slot-scope="record">
          <li
            style="line-height: 20px;"
            v-for="(item, index) in record['times']"
            :key="index"
          >
            {{ item }}
          </li>
        </template>
        <template slot="states" slot-scope="states">
          <div :class="$style.states">
            <li
              v-for="(item, index) in states.split('； ')"
              :key="index"
              style="max-width: 220px;max-height: 150px;overflow-x: hidden;overflow-y: scroll;"
            >
              {{ item }}
            </li>
          </div>
        </template>
        <template slot="operation" slot-scope="record">
          <div :class="$style.operation">
            <a href="javascript:;" @click="Detail(record)">{{
              $t('hat.clockRecord.detailed')
            }}</a>
            <a href="javascript:;" @click="openCalendar(record)">{{
              $t('hat.clockRecord.calendarView')
            }}</a>
          </div>
        </template>
      </a-table>
    </template>
  </FlexColContent>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  getAttendanceList,
  reqGetStaff,
  reqGetStaffInner,
  reqGetStaffExternal,
} from '@/services/attendance/list';
import moment from 'moment';
import Calendar from './components/calendar/calendar.vue';
import PunchDetail from './components/punch-detail.vue';
import { createDrawer, createModal } from '@triascloud/x-components';
import FlexColContent from '@/views/hat/components/flex-col-content';

const UTL_ENUM = {
  dev: `http://dev.app.${process.env.VUE_APP_HOST}/expand/gadget`,
  production: `https://app.${process.env.VUE_APP_HOST}/expand/gadget`,
  sit: `https://sit.app.${process.env.VUE_APP_HOST}/expand/gadget`,
  uat: `https://uat.app.${process.env.VUE_APP_HOST}/expand/gadget`,
};

@Component({
  components: {
    FlexColContent,
  },
})
export default class FixedShift extends Vue {
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  get tipsText() {
    return this.$t('hat.clockRecord.fixedShiftTips', {
      value: `<a href="${
        UTL_ENUM[process.env.VUE_APP_ENV]
      }" target="_blank">${this.$t(
        'hat.managementModal.setting.hint.component',
      )}</a>`,
    });
  }
  loading = false;
  staffs = [{ name: '全部用户', userId: 'u0' }];
  userId = 'u0';
  pagination = {
    'pageSize': 10,
    'current': 1,
    'total': 0,
    'showSizeChanger': true,
    'pageSizeOptions': ['10', '20', '30', '40'],
    'showTotal': total => this.$t('todo.flow.total', { total }),
    'show-quick-jumper': true,
  };
  searchForm = {
    timeRange: [],
  };
  timeString = ['', ''];
  dataSource = [];
  startValue = null;
  endValue = null;
  endOpen = false;
  data = {
    external: {},
    member: {},
  };
  dataSave = {
    external: {},
    member: {},
  };
  value = {
    value: undefined,
  };
  async mounted() {
    this.requestList();
    this.staffList();
  }
  async staffList() {
    const member = await reqGetStaffInner();
    const external = await reqGetStaffExternal();
    this.data.member = member.records;
    this.data.external = external.records;
    this.dataSave = this.data;
  }
  async fetch(value) {
    const result = await reqGetStaff(value);
    this.data = result;
    if (!value) {
      this.data = this.dataSave;
    }
  }
  handleSearch(value) {
    this.value.value = value;
    this.fetch(value);
  }
  async handleChange1(value) {
    this.pagination.current = 1;
    this.userId = value;
    this.requestList();
    if (value == 'u0' || value == 'u1') {
      this.value.value = undefined;
    }
  }

  handleSearchTime(time, timeString) {
    this.timeString = timeString;
    const isClose = timeString.some(item => !item);
    if (isClose) {
      this.requestList();
    }
  }
  async handleSearchTimeOK() {
    this.pagination.current = 1;
    this.requestList();
  }
  async requestList() {
    const [d1, d2] = [
      this.$moment(this.timeString[0]).format('YYYY-MM-DD HH:mm:ss'),
      this.$moment(this.timeString[1]).format('YYYY-MM-DD HH:mm:ss'),
    ];
    var startTime = new Date(d1).getTime();
    var endTime = new Date(d2).getTime();
    let userId = this.userId == 'u0' || this.userId == 'u1' ? '' : this.userId;
    const { pageSize: size, current } = this.pagination;
    this.loading = true;
    let reqData = { current, size };
    if (startTime && endTime) {
      reqData.startTime = startTime;
      reqData.endTime = endTime;
    }
    if (userId) {
      reqData.userIds = userId;
    }
    try {
      let list = await getAttendanceList(reqData);
      this.pagination.pageSize = list.size;
      this.pagination.current = list.current;
      this.pagination.total = list.total;
      this.dataSource = list.records.map(element => {
        const { userId, userName: name } = element;
        !this.staffs.some(item => item.userId == userId) &&
          this.staffs.push({ userId, name });
        var { date, firstTime, lastTime, manHour } = element;
        manHour = manHour == -1 ? 0 : manHour;
        date = date ? moment(date).format('YYYY-MM-DD') : '';
        firstTime = firstTime
          ? moment(firstTime).format('YYYY-MM-DD HH:mm:ss')
          : '';
        lastTime = lastTime
          ? moment(lastTime).format('YYYY-MM-DD HH:mm:ss')
          : '';
        return { ...element, date, firstTime, lastTime, manHour };
      });
      if (!this.dataSource.length) {
        this.dataSource = [];
      }
    } catch (error) {
      // this.$message.error('请求考勤列表失败');
      this.loading = false;
    }
    this.loading = false;
  }
  onChange() {}
  async handleChange() {
    this.reqSelctedList();
  }

  actionWidth = 0;
  get columns() {
    var $t = this.$t;
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: $t('hat.clockRecord.index'),
        width: '50px',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.staff'),
        align: 'left',
        width: '80px',
        customRender: (text, row) => <span>{row.userName}</span>,
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.attendanceDate'),
        align: 'left',
        width: '120px',
        dataIndex: 'date',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.attendanceGroup'),
        align: 'left',
        width: '120px',
        dataIndex: 'group',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.firstCheckTime'),
        align: 'left',
        width: '120px',
        dataIndex: 'firstTime',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.lastCheckTime'),
        align: 'left',
        width: '120px',
        dataIndex: 'lastTime',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.accumulatedWorkHours'),
        align: 'left',
        width: '100px',
        dataIndex: 'manHour',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.attendanceStatus'),
        align: 'left',
        width: '200px',
        dataIndex: 'states',
        ellipsis: true,
        scopedSlots: { customRender: 'states' },
      },
      {
        title: $t('electricBoxMock.columnsInfo.operation'),
        width: this.actionWidth,
        scopedSlots: { customRender: 'operation' },
        ellipsis: true,
        fixed: 'right',
        className: 'action',
        customHeaderCell: async () => {
          await this.$nextTick();
          const widthArr = [];
          const els = document.querySelectorAll('.action');
          for (let index = 0; index < els.length; index++) {
            const { width } = els[index].children[0].getBoundingClientRect();
            widthArr.push(width);
          }
          const maxW = Math.ceil(Math.max(...widthArr));
          this.actionWidth = maxW + 32 || 0;
        },
      },
    ];
  }
  async addFenceHandle() {}
  async Detail(record) {
    const modal = createModal(
      h =>
        h(PunchDetail, {
          props: { record },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        className: 'x-tenant-change--modal',
        closable: true,
        maskClosable: false,
        width: 860,
      },
    );
  }
  async handlePageChange(pagination) {
    this.pagination = pagination;
    this.requestList();
  }
  disabledStartDate(startValue) {
    const endValue = this.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }
  disabledEndDate(endValue) {
    const startValue = this.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return startValue.valueOf() >= endValue.valueOf();
  }
  handleStartOpenChange(open) {
    if (!open) {
      this.endOpen = true;
    }
  }

  openCalendar(record) {
    createDrawer(() => <Calendar record={record} />, {
      closable: true,
      title: this.$t('hat.clockRecord.attendanceRecord'),
      width: '100vw',
    });
  }

  go() {
    window.open(UTL_ENUM[process.env.VUE_APP_ENV]);
  }
}
</script>
<style lang="less" module>
.searchHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
  .tipText {
    align-self: flex-end;
    color: var(--font-info);
    margin-right: auto;
    font-size: 14px;
  }
  .cursor {
    font-size: 15px;
    color: var(--font);
  }
}
.operation {
  display: flex;
  gap: 20px;
  width: max-content;
  flex-wrap: nowrap;
}
.states ::-webkit-scrollbar {
  display: none;
}
</style>
