<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <a-radio-group v-model="activeKey">
          <a-radio-button value="1">
            {{ $t('hat.clockRecord.powerDuration') }}
          </a-radio-button>
          <a-radio-button value="2">
            {{ $t('hat.clockRecord.activityRecords') }}
          </a-radio-button>
          <a-radio-button value="3">
            {{ $t('hat.clockRecord.fixedShifts') }}
          </a-radio-button>
        </a-radio-group>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <power-duration v-if="activeKey === '1'" />
        <activity-record v-if="activeKey === '2'" />
        <fixed-shift v-if="activeKey === '3'" />
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import ActivityRecord from './activity-record.vue';
import FixedShift from './fixed-shift.vue';
import PowerDuration from './power-duration.vue';

@Component({
  components: {
    LayoutContent,
    ActivityRecord,
    FixedShift,
    PowerDuration,
  },
})
export default class ClockRecord extends Vue {
  activeKey = '1';
  async mounted() {
    this.activeKey = this.$route.query.key ? this.$route.query.key : '1';
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;

  .title {
    font-size: 16px;
  }

  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
    // height: 100%;
    // :global(.ant-tabs-content) {
    //   height: calc(100% - 60px);
    // }
    :global(.ant-tabs-bar) {
      margin-bottom: 0;
    }
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
  }

  li {
    line-height: 30px;
  }
}
</style>
