<template>
  <div style="min-height: 400px;">
    <div :class="$style.tipText">
      <div>
        {{
          $t('hat.clockRecord.powerDurations.totalOnlineTime', {
            value: record.totalOnlineTime,
          })
        }}
      </div>
      <div>{{ $t('hat.clockRecord.powerDurations.tips') }}</div>
    </div>
    <a-table
      :columns="columns"
      style="height: 450px;"
      :scroll="{ y: 300 }"
      :pagination="false"
      :transformCellText="({ text }) => text || '-'"
      :dataSource="record.detailList"
    >
      <template slot="action" slot-scope="record">
        <a @click="handleDetail(record)">{{
          $t('hat.clockRecord.detailed')
        }}</a>
      </template>
      <template slot="time" slot-scope="record">
        {{
          record.startTime
            ? `${$moment(record.startTime).format('HH:mm')}-`
            : '-'
        }}{{
          record.endTime ? `${$moment(record.endTime).format('HH:mm')}` : '此刻'
        }}
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import Trail from './hat-trail.vue';
import { getDeviceDetail } from '@/services/smart-hat/device';

@Component({
  components: {},
})
export default class PowerDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) record;

  get columns() {
    return [
      {
        title: this.$t('hat.clockRecord.powerDurations.timeInterval'),
        ellipsis: true,
        scopedSlots: { customRender: 'time' },
      },
      {
        title: this.$t('hat.clockRecord.powerDurations.duration'),
        dataIndex: 'onlineTime',
        customRender: text => (text < 0 ? '-' : text + '小时'),
      },
      {
        title: this.$t('hat.clockRecord.powerDurations.trackDetails'),
        width: 120,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  hatColor = 'ORANGE';
  geofenceId = '';
  async getDeviceDetail(deviceId) {
    try {
      const { color, geofenceId } = await getDeviceDetail(deviceId);
      this.hatColor = color;
      this.geofenceId = geofenceId;
    } catch {
      this.hatColor = 'ORANGE';
    }
  }
  async handleDetail(record) {
    await this.getDeviceDetail(this.record.idxDeviceId);
    let timeRange = [];
    if (record.startTime && record.endTime) {
      timeRange.push(...[record.startTime, record.endTime]);
    }
    createModal(
      () => (
        <Trail
          deviceId={this.record.idxDeviceId}
          fenceId={this.geofenceId}
          timeRange={timeRange}
          hatColor={this.hatColor}
        />
      ),
      {
        width: 850,
        maskClosable: false,
        title: this.$t('hat.clockRecord.powerDurations.trackDetails'),
      },
    );
  }
}
</script>
<style lang="less" module>
.tipText {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
