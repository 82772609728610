import request from '../request';

const serviceName = '/iot-smart-helmet/boottime';

/** @name 开机时长详情分页 */
export function getList(data) {
  return request(`${serviceName}/page`, {
    method: 'GET',
    body: data,
  });
}
/** @name 开机时长导出 */
export function exportExcel(data) {
  return request(`${serviceName}/exportList`, {
    method: 'GET',
    body: data,
  });
}
